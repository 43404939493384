import type { MetaFunction } from "@remix-run/node";

export const meta: MetaFunction = () => {
  return [{ title: "Three Words" }];
};

export default function Index() {
  return (
    <div style={{ fontFamily: "system-ui, sans-serif", lineHeight: "1.8" }}>
      <header>&nbsp;</header>
      <main>
        <hr />
        <hgroup>
          <h1 style={{ color: "#15b0d1", marginTop: 0 }}>workiversary.app</h1>
          <p style={{ color: "#666666" }}>
            <em>the world’s best work anniversary experience</em>
          </p>
        </hgroup>
        <hr />
        <p style={{ paddingTop: "2rem" }}>
          To learn more about this product, go to{" "}
          <strong>
            <a href="https://www.workiversary.com/product" style={{ color: "#15b0d1" }}>
              our product page
            </a>
          </strong>
          .
        </p>
        <p>
          To learn more about the folks behind this product, go to{" "}
          <strong>
            <a href="https://www.workiversary.com" style={{ color: "#15b0d1" }}>
              our web site
            </a>
          </strong>
          .
        </p>
      </main>
      <footer>
        <span className="footer-item">
          Copyright &copy; 2023 <a href="https://www.workiversary.com">The Workiversary Group</a>
        </span>
      </footer>
    </div>
  );
}
